// Font path.
$font-path: "../../fonts/";

// Image path.
$image-path: "../../images/build/";

// Colors.
$color-primary: #044851;
$color-secondary: #1c545d;
$color-tertiary: #000;
$color-quaternary: #363636;

$color-link: $color-primary;
$color-visited: #5ac4be;
$color-hover: #5ac4be;
$color-active: #01353c;
$color-focus: #5ac4be;

// Device sizes:
$device-xlarge: 1200px;
$device-large: 960px;
$device-medium: 768px;
$device-small: 640px;
$device-xsmall: 480px;

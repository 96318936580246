.module__gallery {
	cursor: pointer;
	float: left;
	height: 400px;
	overflow: hidden;
	position: relative;
	width: 100%;

	.swiper-slide {
		height: initial;
	}

	.swiper-pagination {
		height: 50px;
		position: relative;
		text-align: center;
		top: -42px;
	}

	.swiper-pagination-bullet {
		background-color: transparent;
		border: 2px solid #fff;
		border-radius: 50%;
		cursor: pointer;
		display: inline-block;
		height: 10px;
		margin: 0 5px;
		width: 10px;
	}

	.swiper-pagination-bullet-active {
		background-color: #fff;
	}
}

.module__gallery__play-button {
	bottom: 0;
	cursor: pointer;
	display: block;
	height: 50px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	z-index: 3;
}

.icon-play-button-inside-a-circle {
	background-color: $color-primary;
	border-radius: 100%;
	color: #fff;
	font-size: 50px;
	height: 50px;
	line-height: 50px;
	padding: 0 .5px;
}

.module__gallery__preview-image {
	height: 400px;
	width: 100%;
}

.module__gallery__nav {
	position: absolute;
	text-align: center;
	top: 359px;
	width: 100%;
	z-index: 2;
}

.module__gallery__nav__dot {
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 10px;
	width: 10px;
}

.module__gallery__nav__dot--empty {
	background-color: transparent;
	border: 2px solid #fff;
}

.module__gallery__nav__left-arrow,
.module__gallery__nav__right-arrow {
	color: #fff;
	cursor: pointer;
	position: absolute;
	text-shadow: 2px 2px 10px #000;

	&:hover {
		color: #fff;
	}
}

.module__gallery__nav__left-arrow {
	left: 20px;
}

.module__gallery__nav__right-arrow {
	right: 20px;
}

.module__gallery__content {
	background-color: rgba(0, 0, 0, .85);
	display: none;
	height: 100%;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 5;

	.module__gallery__content__modal {
		display: block;
		height: 100%;
		margin: 80px auto 0;
		max-width: 1200px;
		padding: 0 50px;
		position: relative;
	}

	.icon-close-button {
		background-color: #fff;
		border-radius: 100%;
		color: #000;
		cursor: pointer;
		font-size: 12px;
		padding: 4px 6px;
		position: absolute;
		right: 50px;
		top: 150px;
		z-index: 7;
	}

	.module__gallery__content__wrapper {
		align-items: center;
		display: flex;
		height: 100%;
		left: 50%;
		margin-left: -50%;
		max-height: 620px;
		max-width: 1200px;
		position: relative;
		width: 100%;
		z-index: 6;

		// scss-lint:disable IdSelector
		#shield {
			background: transparent;
			bottom: 0;
			height: 90%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			z-index: 1;
		}

		#player {
			position: absolute;
		}

		#player,
		div {
			height: 100%;
			width: 100%;
		}
		// scss-lint:enable IdSelector
	}

	.module__gallery__content__image {
		margin: auto;
		max-height: 620px;
		max-width: 100%;
	}

	.module__gallery__content__sharing {
		display: block;
		left: 0;
		list-style-type: none;
		margin-left: 20px;
		margin-top: 20px;
		padding: 0;
		position: absolute;
	}

	.module__gallery__content__nav {
		display: block;
		list-style-type: none;
		margin-right: 10px;
		margin-top: 24px;
		padding: 0 10px;
		position: absolute;
		right: 0;

		.module__gallery__content__arrow {
			color: #fff;
			cursor: pointer;
		}

		.module__gallery__content__nav__count {
			bottom: 12px;
			color: #fff;
			padding: 0 5px;
		}
	}

	.social__links {
		width: auto;

		li {
			color: #fff;
		}
	}
}

@media (max-width: $device-xsmall) {
	.module__gallery__content .module__gallery__content__modal {
		height: 70%;
	}
}

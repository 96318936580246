.site__certifications {
	display: inline-block;
	height: 35px;

	li {
		display: inline-block;
		height: 35px;
		margin-top: 35px;
		vertical-align: middle;
	}

	a {
		display: block;
		height: 35px;
		margin: 0 20px 0 0;
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;
	}

	.logo-cal-racing-cares {
		background: url($image-path + "/logo-cal-racing-cares.png") no-repeat;
		background-size: contain;
		width: 183px;
	}
}


@media only screen and (max-width: $device-large) {
	.site__certifications {
		display: table;
		margin: 0 auto;
	}
}
